// var myFullpage = new fullpage('#main', {
//     // anchors: ['firstPage', 'secondPage', '3rdPage'],
//     sectionsColor: ['#C63D0F', '#1BBC9B', '#7E8F7C'],
//     scrollBar: true
// });
// $('#main').fullpage({
//     scrollBar: true,
//     onLeave: function(index, nextIndex, direction) {
//         //after leaving section 1
//         if (index == 1 && direction == 'down') {
//             document.querySelector('.header').classList.remove('hide');
//             document.querySelector('.footer').classList.add('hide');
//         } else if(direction =='up' && index == 2) {
//             document.querySelector('.header').classList.add('hide');
//             document.querySelector('.footer').classList.remove('hide');
//         }
//     },
//     afterRender: function(){
//         $('.companies__car').slick({
//             centerMode: true,
//             infinite: true,
//             draggable: false,
//             autoplay: true, /* this is the new line */
//             autoplaySpeed: 2000,
//             slidesToShow: 3,
//             slidesToScroll: 1
//         });
//     }
// });
$('.header__burger').click(()=>{
    document.querySelector('.wrapper').classList.add('show');
    document.querySelector('.main').classList.add('blur');
});
$('.close').click(()=>{
    document.querySelector('.wrapper').classList.remove('show');
    document.querySelector('.main').classList.remove('blur');
});

new Swiper('.companies__slide', {
    slidesPerView: 3,
    spaceBetween: 10,
    centerInsufficientSlides: true
});
new Swiper('.about__wrapper', {
    slidesPerView: 1,
    spaceBetween: 10,
    centerInsufficientSlides: true,
    speed: 900,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        reverseDirection: true,
    }
});

// var swiper = new Swiper('.clients__wrapper', {
//     slidesPerView: 2,
//     slidesPerColumn: 2,
//     //slidesPerColumnFill: "row",
//     slidesPerGroup: 6,
//     spaceBetween: 30,
//     initialSlide : 1,
//     loop: true,
//     grid: {
//         rows: 4,
//     },
//     autoplay: {
//         delay: 1000,
//         disableOnInteraction: false,
//         reverseDirection: false,
//     }
// });


var swiper = new Swiper(".clients__wrapper", {
    spaceBetween: 70,
    centeredSlides: true,
    slidesPerView: 3,
    loop: true,
    speed: 900,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
});
var swiper = new Swiper(".clients__wrapper-reverse", {
    spaceBetween: 30,
    centeredSlides: true,
    slidesPerView: 3,
    loop: true,
    speed: 900,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        reverseDirection: true,
    },
});
// var swiper12 = new Swiper('.clients__wrapper-reverse', {
//     slidesPerView: 2,
//     slidesPerColumn: 2,
//     //slidesPerColumnFill: "row",
//     slidesPerGroup: 4,
//     spaceBetween: 30,
//     initialSlide : 1,
//     speed: 500,
//     loop: true,
//     grid: {
//         rows: 4,
//     },
//     autoplay: {
//         delay: 1000,
//         disableOnInteraction: false,
//         reverseDirection: true,
//     }
// });
// new Swiper('.clients__wrapper', {
//     // fill: column,
//     grid: {
//         rows: 5,
//         column: 5,
//     }
// });


// $(function() {
//     let menu_tops = $('.section:first-of-type').offset().top;        // запоминаем положение меню
//     $(window).scroll(function () {             // отслеживаем событие прокрутки страницы
//         if ($(window).scrollTop() > menu_tops) {  // если прокрутка дошла до меню
//             $('html, body').animate({
//                 scrollTop: menu_tops.next($('.section')).offset().top  // класс объекта к которому приезжаем
//
//             }, 1000);
//             console.log(menu_tops);
//         } else {                                 // прокрутка страницы обратно вверх достигла место "перехода" меню
//
//         }
//     });
// });
        // запоминаем положение меню
var mt = 0;
$(function() { setTimeout(

    $(window).scroll(function () {             // отслеживаем событие прокрутки страницы
        if ($(window).scrollTop() > mt+150) {  // если прокрутка дошла до меню
            mt = $('.section').next('.section').offset().top+mt;
            $('html, body').animate({
                scrollTop: mt // класс объекта к которому приезжаем

            }, 1000);
            console.log( mt);
            console.log($(window).scrollTop());
        }
        // else if($(window).scrollTop() < mt-150) {
        //     console.log('123');
        //     mt = $('.section').next('.section').offset().top-mt;
        //     $('html, body').animate({
        // scrollTop: mt // класс объекта к которому приезжаем
        //
        //         }, 1000);
        //         console.log( mt);
        //     }
    }), 2000);
});



// $(function() {
//     $(window).scroll(function () {             // отслеживаем событие прокрутки страницы
//         if ($(window).scrollTop() < mt) {  // если прокрутка дошла до меню
//             mt = $('.section').next('.section').offset().top-mt;
//             $('html, body').animate({
//                 scrollTop: mt // класс объекта к которому приезжаем
//
//             }, 1000);
//             console.log( mt);
//         }
//     });
// });

$(function() {
    menu_top = $('.section__top').offset().top;        // запоминаем положение меню
    $(window).scroll(function () {             // отслеживаем событие прокрутки страницы
        if ($(window).scrollTop() > menu_top) {  // если прокрутка дошла до меню
            if ($('.menu').css('position') != 'fixed') {  // проверяем, если меню еще не зафиксировано
                console.log('work');
                document.querySelector('.header').classList.remove('hide');
                document.querySelector('.first').classList.add('active');
                document.querySelector('.footer').classList.add('hide');
                // $('html, body').animate({
                //     scrollTop: $(".companies ").offset().top  // класс объекта к которому приезжаем
                // }, 1000);
                // $('.menu').css('position','fixed');  // задаем блоку меню свойство position = fixed
                // $('.menu').css('top','0');           // положение в самом верху
                // $('.content').css('margin-top','80px'); // делаем отступ, чтобы контент не "скакал" в момент фиксации меню
            }
        } else {                                 // прокрутка страницы обратно вверх достигла место "перехода" меню
                console.log('fix');
                document.querySelector('.header').classList.add('hide');
                document.querySelector('.footer').classList.remove('hide');
                document.querySelector('.first').classList.remove('active');
        }
    });
});

// $('.companies__wrapper').slick({
//     infinite: true,
//     draggable: false,
//     autoplay: true, /* this is the new line */
//     autoplaySpeed: 2000,
//     slidesToShow: 1,
//     slidesToScroll: 1
// });
// $('.companies__wrapper').slick({
//
//     responsive: [
//         {
//             breakpoint: 2048,
//             settings:
//                 "unslick"
//         },
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 arrows:false,
//                 dots:true,
//             }
//         }
//     ],
//
// });

// $('.companies__car').slick({
//     centerMode: true,
//     infinite: true,
//     draggable: false,
//     autoplay: true, /* this is the new line */
//     autoplaySpeed: 2000,
//     slidesToShow: 3,
//     slidesToScroll: 1
// });
// var swiper = new Swiper(".swiper", {
//     pagination: {
//         el: ".swiper-pagination",
//         type: "progressbar",
//     },
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },
// });
 // let swiper = new Swiper(".companies__wrapper", {});
// jQuery(document).ready(function() {
//     var mySwiper = new Swiper('.companies__wrapper', {
//         loop: true,
//         noSwiping: false,
//         autoplay: {
//             delay: 3000
//         },
//         // navigation: {
//         //     nextEl: '.swiper-button-next',
//         //     prevEl: '.swiper-button-prev'
//         // },
//     });
// });
// $('.companies__wrapper').slick({
//     // infinite: true,
//     // centerMode: true,
//
//     slidesToShow: 3,
//     slidesToScroll: 1
// });

